<template>
    <div class="layout-body">
        <div class="header">
            <span class="name">{{ selectName.name }}</span>
            <i class="icon" @click="menuVisible?menuVisible = false:menuVisible = true  "></i>

            <div class="box" v-if="menuVisible">
                <ul>
                    <li :key="key" v-for="(item,key) in menu" @click="onLink(item)">{{ item.name }}</li>
                </ul>
            </div>
        </div>
        <div class="iframe" v-if="selectName.id === 1">
          <iframe width="100%" height="100%" src="https://fac.3d.ustep.cn"></iframe>
        </div>
        <div class="principle" v-show="selectName.id === 2">
            <div class="box">
                <img src="../../assets/principle.svg" />
                <a href="javascript:" class="position1" @click="onPosition1">
                    <span class="link" v-if="position1" @click.stop="onRouter"></span>
                </a>
                <a href="javascript:" @click="onPosition2" class="position2">
                    <span class="link" v-if="position2" @click.stop="onRouter"></span>
                </a>
            </div>
        </div>
        <div class="principle" id="principle" v-show="selectName.id === 3">
            <div class="box">
                <img src="../../assets/img2.png" />
                <a href="javascript:" class="position1">
                    <span class="link" id="drag"
                          @touchstart="onMouseDown"
                          @touchend="onMouseUp"
                          @touchmove="onMouseMove"
                          @mousedown="onMouseDown"
                          @mouseup="onMouseUp"
                          @mousemove="onMouseMove"
                          @click.stop="onRouter">
                    </span>
                </a>
            </div>
        </div>
        <div class="header-box" v-show="visible">
            <a href="javascript:" class="close" @click="visible = false">x</a>
            <video width="100%" height="100%" autoplay src="https://vd3.bdstatic.com/mda-kk6c4uuz6xkkx8iw/sc/mda-kk6c4uuz6xkkx8iw.mp4?v_from_s=hkapp-haokan-suzhou&auth_key=1642170582-0-0-cf997ab67a7c48a54b33ba0fd00ae3e5&bcevod_channel=searchbox_feed&pd=1&pt=3&logid=3582651031&vid=10836498648515223791&abtest=&klogid=3582651031" controls="controls">
                您的浏览器不支持 video 标签。
            </video>
        </div>
    </div>
</template>
<script>
import content from './content'
export default content
</script>
<style lang="less">
@import "content";
</style>
