
const content = {
    name:'Content',
    data() {
        return {
            selectName:{},
            menuVisible:false,
            position1:false,
            position2:false,
            position3:false,
            visible:false,
            dragging:false,
            mouseX:0,
            mouseY:0,
            objX:0,
            objY:0,
            menu:[
                {
                    id:1,
                    name:'结构讲解',
                },
                {
                    id:2,
                    name:'电器原理',
                },
                {
                    id:3,
                    name:'故障排查',
                }
            ]
        }
    },
    created() {
        this.selectName = this.menu[0];
    },
    mounted() {
        let dragObj = document.getElementById("drag");

        dragObj.style.left = "-60px";
        dragObj.style.top = "60px";
    },
    methods: {
        onMouseDown(event) {
            document.getElementById('principle').style.overflow = 'hidden'
            event = event || window.event;
            let dragObj = document.getElementById("drag");
            this.dragging = true;
            dragObj.style.position = "absolute";
            let p = this.getPoint(event);

            this.mouseX = p.x;
            this.mouseY = p.y;
            this.objX = parseInt(dragObj.style.left);
            this.objY = parseInt(dragObj.style.top);
        },
        onMouseUp() {
            document.getElementById('principle').style.overflow = 'scroll'
            this.dragging = false
        },
        onMouseMove(event) {
            console.log(event)
            event = event || window.event;
            let p = this.getPoint(event);
            if (this.dragging) {
                let dragObj = document.getElementById("drag");
                dragObj.style.left = parseInt(p.x - this.mouseX + this.objX) + "px";
                dragObj.style.top = parseInt(p.y - this.mouseY + this.objY) + "px";
            }
        },
        /**
         * 获取点坐标
         * @param e
         * @returns {{x: number, y: number}}
         */
        getPoint(e) {
            if (e.touches && e.touches.length > 0) {
                let touch = e.touches[0];
                return { x: touch.pageX,y:touch.pageY}
            }
            return { x: e.offsetX, y: e.offsetY};
        },
        onLink(obj){
            this.selectName = obj
            this.menuVisible = false
        },
        onPosition1(){
            this.position1?this.position1 = false : this.position1 = true
            this.position2 = false
        },
        onPosition2(){
            this.position2?this.position2 = false : this.position2 = true
            this.position1 = false
        },
        onPosition3(){
            this.position3?this.position3 = false : this.position3 = true
        },
        onRouter() {
            this.visible = true
        },
    },
    destroyed () {
    }
}
export default content
